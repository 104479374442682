<template>
    <svg
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <path
            d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 Z M10,4 C11.93,4 13.5,5.57 13.5,7.5 C13.5,9.43 11.93,11 10,11 C8.07,11 6.5,9.43 6.5,7.5 C6.5,5.57 8.07,4 10,4 Z M10,18 C7.97,18 5.57,17.18 3.86,15.12 C5.55,13.8 7.68,13 10,13 C12.32,13 14.45,13.8 16.14,15.12 C14.43,17.18 12.03,18 10,18 Z"
        ></path>
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
