<template>
    <div>
        <IconsBasket />
        <div v-if="basketStore.lines ? true : false" class="basket-indicator">
            {{ quantity }}
        </div>
    </div>
</template>

<script setup>
import { useBasketLines } from '~/composables/transformers/basket';
import { useBasketStore } from '~/stores/basket';
const basketStore = useBasketStore();
const lines = useBasketLines();
const quantity = computed(() => {
    return lines.value.reduce((sum, l) => sum + l.quantity, 0);
});
</script>

<style lang="scss">
.basket-indicator {
    background-color: #31441e;
    color: #fff;
    width: 21px;
    height: 16px;
    border-radius: 8px;
    font-size: 10px;
    text-align: center;
    line-height: 17px;
    top: -10px;
    right: -15px;
    position: absolute;
}
</style>
